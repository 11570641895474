import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { Button } from './Button'
import './CaseStudy.css'

function CaseStudy() {
  return ( 
    <div className="CaseStudy">
    <div className="casestudy-container">
        <div className="casestudy-inset">
            <div className="casestudy-pic">
                <img src="/images/amosupport-interface.png" alt=""  />
            </div>
            <div className="casestudy-text">
                <h3>Case study</h3>
                <h1>AMOSUPPORT: Connected Spatial Computing for the OR</h1>
                <p>Using the latest Mixed Reality technology, Amoupport offers procedural interactive animated anatomical holograms for surgeons and surgical instrument holographic overlays for theatre practitioners. The device company rep guides them remotely via video link and connects them with the rest of the world. </p><p>The surgeon has all the information at their diposal - arranging them in space, while keeping focus on the patient. The result is a more imformative, efficient and safer operation.</p>
                <Link className='casestudy-btns'>
                <Button  buttonStyle='btn--outline' link='/amosupport/#amosupportTop'>Learn how </Button>
                </Link>
            </div>
        </div>
        <div className="quote-container">
            <p>"It will allow us to support cases anywhere in the world whilst calling in expert clinical experience from other Surgeons as well as with specialist implant knowledge from engineers."</p>
        <p>"This will really help us support clinicians in driving the best possible patient outcomes."</p>
        <p>“A game changer for complex limb salvage surgery.“
</p>
        </div>
    </div>
    
    
    </div>
    
  )
}

export default CaseStudy