import React from 'react'
import '../../App.css'
import { Button } from './../Button'
import './../Footer.css'
import { Link } from 'react-router-dom'
import Paragraph from '../Paragraph'
import CookiesHeader from '../CookiesHeader'

function DeleteData() {
  return (
    <>
    <CookiesHeader />
<div class="separator"></div>
<div class="del-container">
<Paragraph 
    title='Do you wish to delete your data?'
    text='Your data is important to us.
There will of course be circumstances when you wish for us to no longer hold it.'
/>
<div class="separator"></div>    
<Paragraph 
    title='What data do we collect and store?'
    text='We store your login information which includes your email address, your username, and your password.  This data is held through our account with Amazon Web Services (AWS) and is encrypted both on the AWS server, and in transit when data is transferred from your phone to the server.

We also collect data that you input on the app such as MCQ scores, your progress through the app, and how you perform through some of the tasks in the app.  This data is linked to your username which may be shared with your mentor, if you have one, so that they can see how you are doing through the app.  This data is also stored securely on our AWS server.

We may also collect data that is not linked to your account.  This can include the model of phone you are using if the app crashes, which can help us continue to update and fix any issues as newer phones are released.'
/>
<div class="separator"></div>    
<Paragraph 
    title='How do I request my data?'
    text='You can request your data by contacting us below.  Please ensure that you are contacting us through the same email address that you used to sign up to the app, this ensures that we are only giving out personal data to the correct user.

If you are requesting your password, we are unable to provide you with this as this is encrypted on our AWS server.  To reset your password, please do this through the app on the login screen.'
/>
<div class="separator"></div>   
<Paragraph 
    title='How do I delete my data?'
    text='You can request to delete all or part of your data that we store.  Contact us below to request to delete all or part of your data.
Any data that is anonymised and not linked to you or your account may be kept to help improve the app experience for other users.  This data could be information such as a collective average of how users performed in a particular task, and it cannot be traced back to your account.'
/> 


<div class="separator"></div> 
<h1>Please note, once the data is deleted it cannot be retrieved.</h1>
<div class="separator"></div> 

<Link className='fl-btn'>
                    <Button 
                    buttonStyle='btn--outline' 
                    onClick={(e) => {
                    window.location.href = "mailto:info@amodisc.com?subject=Amodisc website request&body='Hi I'm interested in Amodisc'";
                    e.preventDefault();
                                    }}
            >Contact us to delete your data</Button>
                     </Link>
</div>
  
    </>
  )
}

export default DeleteData