import React , {useState, useEffect, useRef} from 'react'
//import { Link } from 'react-router-dom'
import { Button } from './Button'
import './Navbar.css'
import { HashLink as Link } from 'react-router-hash-link'

function Navbar() {
const [click, setClick] = useState(false)
const [button,setButton] = useState(true)

const handleClick = () => {setClick(!click)}
const closeMobileMenu = (input) => {
   // scrollToSection(input)
    setClick(false)
}



const showButton = () => {
if(window.innerWidth <= 960){
    setButton(false)
}
else{
    setButton(true)
}
}
window.addEventListener('resize', showButton)
 
useEffect(()=>{showButton()},[])

return (
    <>
    <nav className='navbar'>
<div className="navbar-container">

    <Link to="/#top" className="navbar-logo" onClick={closeMobileMenu}>

    <img src="https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/amodisc_logo_white.png" alt="AMODISC" width='200px' />
        </Link>
<div className="menu-icon" onClick={handleClick}>
<i className={click ? 'fa-solid fa-times' : 'fa-solid fa-bars'}/>
</div>
<ul className={click ? "nav-menu active" : "nav-menu"}>
<li className="nav-item">
    <Link to='/#solutions' className="nav-links" onClick={closeMobileMenu} >
Solutions
    </Link>
    </li>
    <li className="nav-item">
    <Link to='/About/#top' className="nav-links" onClick={closeMobileMenu} >
About
    </Link>
    </li>
    
    <li className="nav-item">
    <Link to='/How/#top' className="nav-links" onClick={closeMobileMenu} >
Let's Build
    </Link>
    </li><li className="nav-item">
    <Link to='/CaseStudies/#top' className="nav-links" onClick={closeMobileMenu} >
Case Studies
    </Link>
    </li>
    <li className="nav-item">
    <Link to='/Partners' className="nav-links" onClick={closeMobileMenu} >
Partners

    </Link>
</li>
<li className="nav-item">
    <Link to='/#contactus' className="nav-links-mobile" onClick={closeMobileMenu} >
Contact Us

    </Link>
</li>

</ul>
{button && <Button buttonStyle='btn--outline' link='/#contactus'>CONTACT US</Button>}
</div>
    </nav>
    </>
  )
}

export default Navbar