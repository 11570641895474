import React from 'react'
import './VideoShow.css'

function VideoShow(props) {
  return (
    <div className='innerD'
    dangerouslySetInnerHTML={{
      __html: `<video className="app__backgroundVideo" autoplay loop muted playsinline>
<source src=${props.source} type="video/mp4" />
Your browser does not support video.
</video>`,
    }}
  
  ></div>
  )
}

export default VideoShow