import React, { useRef, useActionData, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { Button } from './Button'
import './Footer.css'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css'; // import css file from root.



export const ContactUs = () => {
  const form = useRef();

  const alertInfo = (info) => toast.info(info);
  const alertSuccess = (success) => toast.success(success);
  const alertWarning = (warning) => toast.warning(warning, {toastStyle: {boxShadow:'2px 2px 2px 1px rgb(0 0 0 / 20%)'}});
  const alertError = (error) => toast.error(error);


  const sendEmail = (e) => {
    e.preventDefault();

    console.log('form email is set to ....',form.current[1].value)
if(form.current[1].value !== '')
{
  console.log('not empty so send')

    emailjs
      .sendForm('service_v0xeo4p', 'template_cb3y468', form.current, {
        publicKey: '4byGr5J1kvsQnZEsi',
      })
      .then(
        () => {
          console.log('SUCCESS!', form.current[1]);
          //alert('form sent')
          alertSuccess('Your message was sent. Thank you.')
          form.current?.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
          alertError('something went wrong...Please try again')
          //alert('something went wrong...Please try again')
        },
      );
      
        console.log('form is....Sent') 
      }
      else{
        alertWarning('You must provide your email if you wish to contact us')
        console.log('empty form!!! dont send')
      }
  };



  return (
    <>
    <form ref={form} onSubmit={sendEmail}>

<div className="input-areas">
                
                <input type="text" name="from_name" placeholder='your name' className="footer-input" />
                <input type="email" name="user_email" placeholder='your email' className="footer-input" />
                
                <textarea name="message"  placeholder='your message'/>
                <input type="submit" value="Send" className='btn--outline--white' style={{marginBottom:20}}/>
              
              
                </div>

      
    </form>
    <h3>or</h3>
    <Link
    className='btn--outline--white'
    style={{width:'20em', marginTop:20}}
            to='#'
            onClick={(e) => {
                window.location.href = "mailto:info@amodisc.com?subject=Amodisc website request&body='Hi I'm interested in Amodisc'";
                e.preventDefault();
            }}
        >Email us</Link>
    </>
  );
};