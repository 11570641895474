import React from 'react'
import { Button } from './Button'
import './Footer.css'
import { Link } from 'react-router-dom'

import { ContactUs } from './ContactUs'

function Footer() {
  return (
    <div className='footer-container' id='contactus'>
        <section className="footer-subscription">
            <h1 className="footer-subscription-heading">
                Enquire Now
            </h1>
            <p className="footer-subscription-text">
             
            </p>
            
                <ContactUs />
            
        </section>
  
        <div className="footer-links">
            <div className="footer-link-wrapper">
                <div className="footer-link-items">
                   <div className="footer-item">
                     <h3>Find us</h3>
                     <p>3 Trinder house</p>
                     <p>Free Street</p>
                     <p>Southampton</p>
                     <p>UK SO32 1EE</p>
                   </div>
                   <div className="footer-item">
                     <h3>Get in touch</h3>
                     <p>info@amodisc.com</p>

                     <Link className='fl-btn'>
                    <Button 
                    buttonStyle='btn--outline--white' 
                    onClick={(e) => {
                    window.location.href = "mailto:info@amodisc.com?subject=Amodisc website request&body='Hi I'm interested in Amodisc'";
                    e.preventDefault();
                                    }}
            >Email us</Button>
                     </Link>
                     
                   </div>
                   </div>
                   <div className="footer-link-items">
                   <div className="footer-item">
                     <h3>Subscribe to our Newsletter</h3>
                     <Link className='fl-btn'>
                    <Button buttonStyle='btn--outline--white'>Subscribe</Button>
                     </Link>
                   </div>
                   <div className="footer-item">
                     <h3>Terms of service</h3>
                     <Link className='fl-btn'>
                    <Button buttonStyle='btn--outline--white'>Click</Button>
                     </Link>
                   </div>

                   <div className="footer-item">
                     <h3 style={{color:'red'}}>Delete my data</h3>
                     <Link className='fl-btn'>
                    <Button buttonStyle='btn--outline' link='/deletedata'>Delete</Button>
                     </Link>
                   </div>
 </div>
 </div>
 </div>  
<div class="prpo">
 <Link className='fl-btn'>
                    <Button buttonStyle='btn--outline--white' link='/privacypolicy/#top'>Privacy Policy</Button>
                     </Link>
        </div>
        <section className="social-media">
            <div className="social-media-wrap">
                {/* <div className="footer-logo">
                    <Link to='#1' className="social-logo">
                        AMODISC <i className='fa-solid fa-house'/>
                    </Link>
                </div> */}
                <small className="websight-rights">AMODISC LTD <i className="fa-regular fa-copyright" /> 2024 </small>
                <div className="social-icons">
                    <Link className="social-icon-link"
                    to="/"
                    target='_blank'
                    aria-label='Facebook'>

                        <i className="fa-brands fa-facebook"></i>
                    </Link>
                    <Link className="social-icon-link"
                    to="/"
                    target='_blank'
                    aria-label='Twitter'>

                        <i className="fa-brands fa-x-twitter"></i>
                    </Link>
                    <Link className="social-icon-link"
                    to="/"
                    target='_blank'
                    aria-label='Instagram'>

                        <i className="fa-brands fa-instagram"></i>
                    </Link>
                    <Link className="social-icon-link"
                    to="/"
                    target='_blank'
                    aria-label='Linkedin'>

                        <i className="fa-brands fa-linkedin"></i>
                    </Link>
                </div>
            </div>
        </section>
    </div>
   
  )
}

export default Footer