import React from 'react'
import '../../App.css'
import TopPic from '../TopPic'
import VidSection from '../VidSection'
import TextSection from '../TextSection'
import PureGrid from '../PureGrid'
import devices from '../../jsons/devices.json'
import vidList from '../../jsons/vidList.json'
import { Button } from '../Button'

import Footer from '../Footer'
import CookiesHeader from '../CookiesHeader'

export default function AmoCourse(){
    const vidsectionText = (
        <>
        <p>We love to come up with innovative ways of delivering interactive educational content.</p>
        <p>Our unified training platform is modular and scalable so that you can build the content with us at your own pace, expanding your reach and student base.</p>
        <p>Be certain that the people you teach have sufficient knowledge and skills to take on new procedures. Amocourse helps you select the 'cream of the crop'.</p>
        </>
    )
    
        return (
            <>
        <div id='amocourseTop'></div>
        <CookiesHeader />
       <TopPic 
       text='AMOcourse'  
       subtext ='Deliver training where it matters most' 
       subtext2='Where healthcare systems are being built, we need to provide education for the whole team. The amodisc platform can deliver comprehensive training courses on any device suitable for the content and environment in which you wish to train. A world where all trainers and trainees are supported'  
       imgsrc="url(/images/amocourse-img.jpg)"blackover='true'
       />
       <VidSection 
    title='Ideal for NGOs, LMICs or emerging and leading economies wishing to expand healthcare' 
    subtitle={vidsectionText}
    source={vidList.amocourse}
       />
       <TextSection
       title="We deliver your content on the device that best suits your training needs"
       />
         <PureGrid contents={devices}/> 
         <div style={{backgroundColor:'white', display:'flex', justifyContent:'center'}} >
         <Button className='btns' buttonStyle='btn--outline' link='/#contactus'>Contact us</Button>
        </div> 
        <TextSection
       subtitle="If you want to learn about delivering a healthcare training platform, please enquire below..."
       />
       <Footer />
            </>
        )
}