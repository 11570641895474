import React from 'react'
import '../../App.css'

import Footer from '../Footer'
import TopPic from '../TopPic'
import Team from '../Team'

import VidSection from '../VidSection'
import TextSectionRight from '../TextSectionRight'
import TextSectionLeft from '../TextSectionLeft'
import GridRow from '../GridRow'
import devices from '../../../src/jsons/devices.json'
import PureGrid from '../PureGrid'
import BM from '../BM'
import Separator from '../Separator'
import vidList from '../../jsons/vidList.json'
import CookiesHeader from '../CookiesHeader'


function How() {
  return (
    <>
    <div id='top'></div>
    <CookiesHeader />
     <TopPic 
   text='Collaboration'  
   subtext ='We help you create the content how you want' 
   subtext2='We provide the platform. We work with you to design, build and deploy the content so that it suits your needs.'  
   imgsrc="url('https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/seeds2K.jpg')"
  blackover='true'
   />
<Separator />
<BM />

   <VidSection 
title='Build your training programme to suit you' 
source={vidList.amocourse}
   />
   
     <PureGrid contents={devices}/>
<div className="callUs" style= {{backgroundColor:'white', textAlign:'center', paddingBottom:20, paddingLeft:10, paddingRight:10}}><h2>To build your new training programme, contact us below</h2></div>
     
     
     <Footer/>
    
    </>
    
  )
}

export default How