import React from 'react'
import { Link } from 'react-router-dom'
import './Trusted.css'
import { Button } from './Button'

function Trusted() {
  return (
    <div className='Trusted'>
           <div className='trusted-container'>
        <h1>Trusted by the best</h1>
        <div className="logos-wrapper">
    <div className="logos">
        <div className="logo-holder" >
            <img src="https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/NHS-logo.png" alt=""  height={100}/>
        </div>
        <div className="logo-holder" >
            <img src="https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/Unitar-Logo.png" alt=""  height={100} style={{backgroundColor:'white', borderRadius:10}}/>
        </div>
        </div>
        <div className="logos"> 
        <div className="logo-holder">
            <img src="https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/operation-smile.jpg" alt=""  height={100}/>
        </div>
        </div>
        <div className="logos"> 
        <div className="logo-holder">
            <img src="https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/DBT_Red.jpg" alt=""  height={100}/>
        </div>
        <div className="logo-holder">
            <img src="https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/aouk.png" alt="" height={100} style={{backgroundColor:'#042d97', borderRadius:10}} />
        </div>
        </div>
    </div>
    </div>
    </div> 
    
  )
}

export default Trusted