import './App.css';
import React from 'react';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Routes , Route} from 'react-router-dom'
import Home from './components/pages/Home'
import AmoTutor from './components/pages/AmoTutor';
import AmoCourse from './components/pages/AmoCourse';
import AmoSupport from './components/pages/AmoSupport';
import About from './components/pages/About';
import How from './components/pages/How';
import CaseStudies from './components/pages/CaseStudies'
import Partners from './components/pages/Partners';
import DeleteData from './components/pages/DeleteData'
import PrivacyPolicy from './components/pages/PrivacyPolicy';

import { ToastContainer, toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css'; // import css file from root.

// cookie stuff to follow
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Button } from './components/Button'
import { Link } from 'react-router-dom'
//////////////////////////


// function RootComponent() {
//   return (
//     <>
//       <App />
//       {/* Add ToastContainer from your root component. */}
//       {/* If you use next.js, add it to app.js */}
//       {/* If no floatingTime, the default is 3000ms. */}
//       <ToastContainer floatingTime={5000} />
//     </>
//   );
// }

function App() {
  return (
   <>
   
   <ToastContainer floatingTime={5000}  />


   <Router>  
    
    <Navbar />
   <Routes>
    <Route path='/' exact Component={Home} />
    <Route path='/amotutor' exact Component={AmoTutor} />
    <Route path='/amocourse' exact Component={AmoCourse} />
    <Route path='/amosupport' exact Component={AmoSupport} />
    <Route path='/about' exact Component={About} />
    <Route path='/how' exact Component={How} />
    <Route path='/casestudies' exact Component={CaseStudies} />
    <Route path='/partners' exact Component={Partners} />
    <Route path='/deletedata' exact Component={DeleteData} />
    <Route path='/privacypolicy' exact Component={PrivacyPolicy} />
    </Routes>
   </Router>

</>
  
  );
}

export default App;
