import React from 'react'
// cookie stuff to follow
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Button } from './Button'
import { Link } from 'react-router-dom'
//////////////////////////

function CookiesHeader() {
  return (
   <>
    <CookieConsent
  location="top"
  buttonText="Accept Essentials"
  cookieName="myAwesomeCookieName2"
  style={{ background: "rgb(100,100,100)" , zIndex: 100000, height:'fit-content', color:"white", padding:40}}
  buttonStyle={{ backgroundColor: "rgb(0,255,0)", fontSize: "13px" }}
  expires={150}

>
COOKIES{" "}<br/><br />
  <span style={{ fontSize: "1em" }}>This website uses only essential cookies to enhance the user experience. Please read our Privacy Policy.  Click 'Accept Essentials' if you consent to proceed. Do not visit the website if you do not consent to the use of any cookies.</span>
  <span ><div style={{margin:20}}><Button className='btns' buttonStyle='btn--outline--white' link='/privacypolicy'>Privacy Policy</Button></div></span>
</CookieConsent>
</>
  )
}

export default CookiesHeader