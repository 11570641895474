import React from 'react'
import Model from './svg/model.svg'
import './BM.css'

function BM() {
  return (
<div className="businessModel">
    <h1>Develop Deploy License Scale</h1>
    <div className="svgHolder"><img src={Model} alt="" /></div>
</div>

  )
}

export default BM