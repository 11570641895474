import React from 'react'
import VideoShow from './VideoShow'
import './VidSection.css'

function VidSectionLeft(props) {
//const source= `${process.env.PUBLIC_URL}https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/videos/Hippo.mp4`
const source = `${process.env.PUBLIC_URL}${props.source}`

  return (
    <>
    <div className="separator" style={{backgroundColor:props.background}} ></div>
  <div className='vidSectionLeft' style={{backgroundColor:props.background}}>
  <div className="videoHolder"  style={{backgroundColor:props.background}}>
 
<video  autoplay loop muted playsinline controls>
<source src={source} type="video/mp4" />
</video>
 
 </div>
    <div className="vidsectextbits" style={{backgroundColor:props.background}}>
        <h1 style={{color:props.titlecolour}}>{props.title}</h1>
        <div style={{color:props.textcolour, fontWeight:props.textcolour==='white'? 200:400}}>{props.subtitle}</div>
    </div>
    
    </div>
    <div className="separator" style={{backgroundColor:props.background}}></div>
</>
  )
}

export default VidSectionLeft