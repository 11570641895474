import React from 'react'
import '../../App.css'
import TopPic from '../TopPic'
import VidSection from '../VidSection'
import TextSection from '../TextSection'
import PureGrid from '../PureGrid'
import devices from '../../jsons/devices.json'
import vidList from '../../jsons/vidList.json'
import { Button } from '../Button'

import Footer from '../Footer'
import CookiesHeader from '../CookiesHeader'

export default function AmoTutor(){
const vidsectionText = (
    <>
    <p>Mentors can interactively customise their library of procedures.</p>
    <p>Trainees can rehearse those procedures and connect to their mentors on each step.</p>
    <p>Education Institutions have visibility on the levels of engagement and have confidence that the training is being delivered. </p>
    </>
)

    return (
        <>
    <div id='amotutorTop'></div>
    <CookiesHeader />
   <TopPic 
   text='AMOtutor'  
   subtext ='Personalised training for all' 
   subtext2='Our Unified Training Platform delivers AI driven content that connects mentors to their students.  Be they surgeons, medics, nurses, bio-engineers, we can cater for all.'  
   imgsrc="url(/images/amotutor-img.jpg)"blackover='true'
   />
   <VidSection 
title='Ideal for Academic Affiliations and Educational Institutions' 
subtitle={vidsectionText}
source={vidList.amotutor}
   />
   <TextSection
   title="We deliver your content on the device that best suits your training needs"
   />
     <PureGrid contents={devices}/> 
     <div style={{backgroundColor:'white', display:'flex', justifyContent:'center'}} >
     <Button className='btns' buttonStyle='btn--outline' link='/#contactus'>Contact us</Button>
    </div> 
    <TextSection
   subtitle="If you want to learn about building a personalised course that connects mentors and trainees, please enquire below..."
   />
   <Footer />
        </>
    )
}