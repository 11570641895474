import React from 'react'
import '../App.css' 
import './TextSectionRight.css'

function TextSectionRight(props) {
  return (
    <>
    <div className="separator"></div>
    <div className='tsr'>
    <div className="titleBox">
      <h1 style={{color:props.titleCol}}>{props.title}</h1>
      <div style={{color:props.subtitleCol}}> {props.subtitle}</div>
      <div className="imageBox">
      <img src={props.image} alt="" width="50%" />  
     </div>
    </div>
    <div className="explainBox">
      {[props.explain]}
    </div>

    </div>
  
    </>
  )
}

export default TextSectionRight