import React from 'react'
import './Button.css'
import { HashLink as Link } from 'react-router-hash-link'


export const Button = ({children, type, onClick, buttonStyle, buttonSize, link}) => {

    const STYLES = ['btn--primary', 'btn--outline', 'btn--outline--white']
    const SIZES = ['btn--medium', 'btn--large']
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

     return (
        <>
<Link to={link} className='btn-mobile'>
<button 
className={`btn ${checkButtonStyle} ${checkButtonSize}`}
onClick={onClick}
type ={type}
>
{children}
</button>
</Link>
</>
     )
}