import React from 'react'

function Paragraph(props) {
  return (
    <>
        <div class="del-para">
<h2>{props.title}</h2>
<p>{props.text} </p>
</div>

    </>
  )
}

export default Paragraph