import React, { useState, useEffect, useRef } from 'react'
import '../App.css' 
import './TopPic.css'

function TopPic(props) {
const bkgdimg = props.imgsrc
const [height, setHeight] = useState(0)
const ref = useRef(null)

useEffect(() => {
  setHeight(ref.current.clientHeight)
},[ref])

  return (
    <div className='topPic' style={{backgroundImage : bkgdimg}} ref = {ref}>
       { props.blackover === "true" ?  <img src='/images/blackover.png' className='imageOverlay' style={{height:height}}/> : <></>}
       
       
      <div className="TPtextbits">
      <h2>{props.text}</h2> 
      <h1>{props.subtext}</h1>
      <h3>{props.subtext2}</h3>
      </div>
        
    </div>
  )
}

export default TopPic