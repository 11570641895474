import React from 'react'
import { Link } from 'react-router-dom'
import './WhoWeHelp.css'
import { Button } from './Button'

function WhoWeHelp() {
  
    return (
        <div className='WhoWeHelp'>
            <div className="fromTrainee__container">
        <div className="fromTrainee__wrapper">
           
            <div className="fromTrainee__textcontiner">
            <p>Who we help</p>
    <h1>From clinical students to industry</h1>
    <h3>NGO & LMICs   </h3>
    <h3>Training Institutions </h3>
    <h3>Manufacturers & Industry </h3>
    
    <div className="FromTrainee-btns">
        <Button className='btns' buttonStyle='btn--outline' link='/casestudies/#top'>Learn more</Button>
    </div>
    </div>
    <img src="https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/handing-over-scalpel.jpg" alt="" className="fromTrainee__picFrame" />
    </div>
    
    </div>
    
    
        </div>
      )
    }

export default WhoWeHelp