import React from 'react'
import TopPic from '../TopPic'
import Separator from '../Separator'
import TextSectionRight from '../TextSectionRight'
import PureGrid from '../PureGrid'
import partnerlogos from '../../jsons/partnerLogos.json'
import CookiesHeader from '../CookiesHeader'

function Partners() {
const logos = partnerlogos

  return (
    <>
        <div id='partnerstop'></div>
    <CookiesHeader />
     <TopPic 
   text='Partners'  
   subtext ='Working with our partners' 
   subtext2='Building healthcare ecosystems by supporting medical education.'  
   imgsrc="url('https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/AdobeStock_707340623.jpeg')"
  blackover='true'
   />


<PureGrid contents={logos}/>




    </>
  )
}

export default Partners