import React from 'react'
import Paragraph from '../Paragraph'

function PrivacyPolicy() {

const para1= (
    <>
<p>This is the privacy notice of Amodisc Ltd. It sets out how Amodisc Ltd uses and protects any information that you give to Amodisc Ltd when you use our websites (including subsidiaries of) and applications owned by us. 
Policy key definitions:
</p>
<p>-  "I", "our", "us", or "we" refer to the business, Amodisc Ltd.</p>
<p>-  "you", "the user" refer to the person(s) using this website.</p>
<p>
-  GDPR means General Data Protection Act.
</p>
<p>
-  PECR means Privacy & Electronic Communications Regulation.
</p>
<p>
-  ICO means Information Commissioner's Office.
</p>
<p>
-  Cookies mean small files stored on a users computer or device.
</p>
 
<p>

We are registered with the ICO under the Data Protection Register, our registration number is ZA381515.
</p>
<p>
We are company number 09840610 registered in United Kingdom.
</p>
<p>
Our registered office is at 3a Market Place, Woodstock, England, OX20 1SY.
</p>

</>
)

const para2 = (
    <>
<p>
1. This is a notice to inform you of our policy about all information that we record about you. It sets out the conditions under which we may process any information that we collect from you, or that you provide to us. It covers information that could identify you (“personal information”) and information that could not. In the context of the law and this notice, “process” means collect, store, transfer, use or otherwise act on information.
</p>
<p>
   2. We regret that if there are one or more points below with which you are not happy, your only recourse is to leave our website immediately. 
    </p>
    <p>
   3. We take seriously the protection of your privacy and confidentiality. We understand that all visitors to our website are entitled to know that their personal data will not be used for any purpose unintended by them, and will not accidentally fall into the hands of a third party. 
</p>
<p>
    4. We undertake to preserve the confidentiality of all information you provide to us, and hope that you reciprocate. 
    </p>
    <p>
    5. Our policy complies with UK law accordingly implemented, including that required by the EU General Data Protection Regulation (GDPR).
</p>
<p>
    6. The law requires us to tell you about your rights and our obligations to you in regards to the processing and control of your personal data. We do this now, by requesting that you read the information provided at www.knowyourprivacyrights.org
    </p>
    <p>
    7. Except as set out below, we do not share, or sell, or disclose to a third party, any information collected through our website.
</p>
    </>
)

const para3= (
    <>
        <p>
        When you create an account on our website, buy a product or service from us, or otherwise agree to our terms and conditions, a contract is formed between you and us.
In order to carry out our obligations under that contract we must process the information you give us. Some of this information may be personal information.  
        </p>
        <p>
        We may use it in order to: 
        </p>
        <p>
            1. verify your identity for security purposes
        </p>
        <p>
            2. sell products to you
        </p>
        <p>
            3. provide you with our services
        </p>
        <p>
        4. provide you with suggestions and advice on products, services and how to obtain the most from using our website
        </p>
        <p>
        We process this information on the basis there is a contract between us, or that you have requested we use the information before we enter into a legal contract.

​

Additionally, we may aggregate this information in a general way and use it to provide class information, for example to monitor our performance with respect to a particular service we provide. If we use it for this purpose, you as an individual will not be personally identifiable.

​

We shall continue to process this information until the contract between us ends or is terminated by either party under the terms of the contract. 
        </p>
    </>
)

const para4 = (
    <>
        <p>
        We may process information on the basis there is a legitimate interest, either to you or to us, of doing so.

Where we process your information on this basis, we do after having given careful consideration to:  
        </p>
        <p>
            - whether the same objective could be achieved through other means
        </p>
        <p>
            - whether processing (or not processing) might cause you harm
        </p>
        <p>
            - whether you would expect us to process your data, and whether you would, in the round, consider it reasonable to do so
        </p>
        <br/>
        <p>
            For example, we may process your data on this basis for the purposes of:
        </p>
        <p>
- record-keeping for the proper and necessary administration of our business
        </p>
        <p>
            - responding to unsolicited communication from you to which we believe you would expect a response
        </p>
        <p>
            - protecting and asserting the legal rights of any party
        </p>
        <p>
            - insuring against or obtaining professional advice that is required to manage business risk
        </p>
        <p>
            - protecting your interests where we believe we have a duty to do so
        </p>
    </>
)
const para6 = (
    <>
        <p>
        Our website allows you to post information with a view to that information being read, copied, downloaded, or used by other people.
Examples include:
        </p>
        <p>
            1. posting a message on our forum
        </p>
        <p>
            2. tagging an image
        </p>
        <p>
            3. clicking on an icon next to another visitor’s message to convey your agreement, disagreement or thanks
        </p>
        <p>
        In posting personal information, it is up to you to satisfy yourself about the privacy level of every person who might use it.

 

We do store it, and we reserve a right to use it in the future in any way we decide.

 

Once your information enters the public domain, we have no control over what any individual third party may do with it.

 

We accept no responsibility for their actions at any time.

 

Provided your request is reasonable and there is no legal basis for us to retain it, then at our discretion we may agree to your request to delete personal information that you have posted. You can make a request by contacting us at info@amodisc.com.
        </p>

    </>
)

const para7= (
    <>
        <p>
        Our websites are hosted in England.
We may also use outsourced services in countries outside the European Union from time to time in other aspects of our business.
Accordingly data obtained within the UK or any other country could be processed outside the European Union.
For example, some of the software our website uses may have been developed in the United States of America or in Australia.
We use the following safeguards with respect to data transferred outside the European Union:
        </p>
        <p>
            1. the processor is within the same corporate group as our business or organisation and abides by the same binding corporate rules regarding data processing.
        </p>
        <p>
            2. we comply with a code of conduct approved by a supervisory authority in the European Union , specifically that in the country of England
        </p>
        <p>
            3. we are certified under an approved certification mechanism as provided for in the GDPR
        </p>
    </>
)

const para8 = (
    <>
        <p>
1. At any time you may review or update personally identifiable information that we hold about you, by signing in to your account on our website, app(s) or requesting the information if this is not applicable.
        </p>
        <p>
            2. To obtain a copy of any information you may send us a request at info@amodisc.com. 
        </p>
        <p>
            3. After receiving the request, we will tell you when we expect to provide you with the information, and whether we require any fee for providing it to you.
        </p>
    </>
)

const para9 = (
    <>
        <p>
            1. We do not sell products or provide services for purchase by children, nor do we market to children.
        </p>
        <p>
            2. If you are under 18, you may use our website only with consent from a parent or guardian
        </p>
    </>
)

const para10 = (
    <>
<p>
    1. If you are not happy with our privacy policy or if have any complaint then you should tell us by email. Our address is info@amodisc.com.
</p>
<p>
    2. If a dispute is not settled then we hope you will agree to attempt to resolve it by engaging in good faith with us in a process of mediation or arbitration.
</p>
<p>
    3. If you are in any way dissatisfied about how we process your personal information, you have a right to lodge a complaint with the Information Commissioner's Office. This can be done at https://ico.org.uk/concerns/
</p>
    </>
)

const para11 = (
    <>
<p>
Except as otherwise mentioned in this privacy notice, we keep your personal information only for as long as required by us:
</p>
<p>
1. to provide you with the services you have requested;
</p>
<p>
2. to comply with other law, including for the period demanded by our tax authorities;
</p>
<p>
3. to support a claim or defence in court.
</p>
    </>
)

  return (
    <>
    <div className='top' />
<div class="pp" style={{backgroundColor:'white'}}>

<div class="separator"></div>
<Paragraph 
title='Amodisc Privacy Notice'
text={para1} 
/>
<div class="separator"></div>


<Paragraph 
title='Introduction'
text={para2} 
/>
<div class="separator"></div>


<Paragraph 
title='What we collect'
text='During the registration process we may collect the following information: name, contact information, profession, stage of training (as applicable), location, interests, data generated from Amodisc Ltd apps in regard to training performance and engagement.' 
/>
<div class="separator"></div>


<Paragraph 
title='The bases on which we process information about you'
text='The law requires us to determine under which of six defined bases we process different categories of your personal information, and to notify you of the basis for each category.

If a basis on which we process your personal information is no longer relevant then we shall immediately stop processing your data.

If the basis changes then if required by law we shall notify you of the change and of any new basis under which we have determined that we can continue to process your information.' 
/>
<div class="separator"></div>


<Paragraph 
title='Information we process because we have a contractual obligation with you'
text={para3} 
/>
<div class="separator"></div>

<Paragraph 
title='Information we process with your consent'
text='Through certain actions when otherwise there is no contractual relationship between us, such as when you browse our website or ask us to provide you more information about our business, including job opportunities and our products and services, you provide your consent to us to process information that may be personal information.


Wherever possible, we aim to obtain your explicit consent to process this information, for example, by asking you to agree to our use of cookies.


Sometimes you might give your consent implicitly, such as when you send us a message by e-mail to which you would reasonably expect us to reply.


Except where you have consented to our use of your information for a specific purpose, we do not use your information in any way that would identify you personally. We may aggregate it in a general way and use it to provide class information, for example to monitor the performance of a particular page on our website.


If you have given us explicit permission to do so, we may from time to time pass your name and contact information to selected associates whom we consider may provide services or products you would find useful.


We continue to process your information on this basis until you withdraw your consent or it can be reasonably assumed that your consent no longer exists.


You may withdraw your consent at any time by instructing us info@amodisc.com. However, if you do so, you may not be able to use our website or our services further.' 
/>
<div class="separator"></div>

<Paragraph 
title='Information we process for the purposes of legitimate interests'
text={para4} 
/>
<div class="separator"></div>
<Paragraph 
title='Information we process because we have a legal obligation'
text='We are subject to the law like everyone else. Sometimes, we must process your information in order to comply with a statutory obligation.
For example, we may be required to give information to legal authorities if they so request or if they have the proper authorisation such as a search warrant or court order.
This may include your personal information.'
/>
<div class="separator"></div>
<h2>Specific uses of information you provide to us </h2>
<div class="separator"></div>
<Paragraph 
title='Information provided on the understanding that it will be shared with a third party'
text={para6}
/>
<div class="separator"></div>
<Paragraph 
title='Complaints regarding content on our website'
text='We attempt to moderate user generated content, but we are not always able to do so as soon as that content is published.

If you complain about any of the content on our website, we shall investigate your complaint.

If we feel it is justified or if we believe the law requires us to do so, we shall remove the content while we investigate.

Free speech is a fundamental right, so we have to make a judgment as to whose right will be obstructed: yours, or that of the person who posted the content that offends you.

If we think your complaint is vexatious or without any basis, we shall not correspond with you about it.'
/>
<div class="separator"></div>

<Paragraph 
title='Job application and employment'
text='If you send us information in connection with a job application, we may keep it for up to three years in case we decide to contact you at a later date.
If we employ you, we collect information about you and your work from time to time throughout the period of your employment. This information will be used only for purposes directly relevant to your employment. After your employment has ended, we will keep your file for six years before destroying or deleting it.'
/>
<div class="separator"></div>

<Paragraph 
title='Sending a message to our support team'
text='When you contact us, whether by telephone, through our website or by e-mail, we collect the data you have given to us in order to reply with the information you need.
We record your request and our reply in order to increase the efficiency of our business.
We keep personally identifiable information associated with your message, such as your name and email address so as to be able to track our communications with you to provide a high quality service.'
/>
<div class="separator"></div>

<Paragraph 
title='Complaining'
text='When we receive a complaint, we record all the information you have given to us.

We use that information to resolve your complaint.

If your complaint reasonably requires us to contact some other person, we may decide to give to that other person some of the information contained in your complaint. We do this as infrequently as possible, but it is a matter for our sole discretion as to whether we do give information, and if we do, what that information is.

We may also compile statistics showing information obtained from this source to assess the level of service we provide, but not in a way that could identify you or any other person.'
/>
<div class="separator"></div>

<Paragraph 
title='Affiliate and business partner information'
text='This is information given to us by you in your capacity as an affiliate of us or as a business partner.
It allows us to recognise visitors that you have referred to us, and to credit to you commission due for such referrals. It also includes information that allows us to transfer commission to you.
The information is not used for any other purpose.
We undertake to preserve the confidentiality of the information and of the terms of our relationship. 
We expect any affiliate or partner to agree to reciprocate this policy.'
/>
<div class="separator"></div>
<h2>Use of information we collect through automated systems when you visit our website</h2>
<Paragraph 
title='Cookies'
text="Cookies are small text files that are placed on your computer's hard drive by your web browser when you visit any website. They allow information gathered on one web page to be stored until it is needed for use on another, allowing a website to provide you with a personalised experience and the website owner with statistics about how you use the website so that it can be improved.
Our website does not use first or third party cookies. It does use third party requests. These are request that are made from a user to an external service. Despite the fact that these requests don't set any cookies, they can still transfer privacy information to third parties. Google Analytics for example works through third party requests."
/>
<div class="separator"></div>
<Paragraph 
title='Personal identifiers from your browsing activity'
text="Requests by your web browser to our servers for web pages and other content on our website are recorded.
We record information such as your geographical location, your Internet service provider and your IP address. We also record information about the software you are using to browse our website, such as the type of computer or device and the screen resolution.
We use this information in aggregate to assess the popularity of the webpages on our website and how we perform in providing content to you.
If combined with other information we know about you from previous visits, the data possibly could be used to identify you personally, even if you are not signed in to our website."
/>
<div class="separator"></div>
<h2>Disclosure and sharing of your information</h2>
<Paragraph 
title='Information we obtain from third parties'
text="Although we do not disclose your personal information to any third party (except as set out in this notice), we sometimes receive data that is indirectly made up from your personal information from third parties whose services we use."
/>
<div class="separator"></div>

<Paragraph 
title='Third party advertising on our website'
text="Third parties may advertise on our website. In doing so, those parties, their agents or other companies working for them may use technology that automatically collects information about you when their advertisement is displayed on our website.
They may also use other technology such as cookies or JavaScript to personalise the content of, and to measure the performance of their adverts.
We do not have control over these technologies or the data that these parties obtain. Accordingly, this privacy notice does not cover the information practices of these third parties."
/>
<div class="separator"></div>

<Paragraph 
title='Credit reference'
text="To assist in combating fraud, we share information with credit reference agencies, so far as it relates to clients or customers who instruct their credit card issuer to cancel payment to us without having first provided an acceptable reason to us and given us the opportunity to refund their money."
/>
<div class="separator"></div>

<Paragraph 
title='Data may be processed outside the European Union'
text={para7}
/>
<div class="separator"></div>
<h2>Access to your own information</h2>

<Paragraph 
title='Access to your personal information'
text={para8}
/>
<div class="separator"></div>

<Paragraph 
title='Removal of your information'
text="If you wish us to remove personally identifiable information from our website, you may contact us at info@amodisc.com.

This may limit the service we can provide to you."
/>
<div class="separator"></div>

<Paragraph 
title='Verification of your information'
text="When we receive any request to access, edit or delete personal identifiable information we shall first take reasonable steps to verify your identity before granting you access or otherwise taking any action. This is important to safeguard your information."
/>
<div class="separator"></div>
<h2>Other matters</h2>
<Paragraph 
title='Use of site by children'
text={para9}
/>
<div class="separator"></div>

<Paragraph 
title='How you can complain'
text={para10}
/>
<div class="separator"></div>

<Paragraph 
title='Retention period for personal data'
text={para11}
/>
<div class="separator"></div>

<Paragraph 
title='Compliance with the law'
text="Our privacy policy has been compiled so as to comply with the law of every country or legal jurisdiction in which we aim to do business. If you think it fails to satisfy the law of your jurisdiction, we should like to hear from you. However, ultimately it is your choice as to whether you wish to use our website."
/>
<div class="separator"></div>
<Paragraph 
title='Review of this privacy policy'
text="We may update this privacy notice from time to time as necessary. The terms that apply to you are those posted here on our website on the day you use our website. We advise you to print a copy for your records.
If we make a change to this policy that, in our sole discretion, is material, we will notify you via an app notification or email to the email address associated with your account. By continuing to access or use our services after those changes become effective, you agree to be bound by the revised Privacy Policy.
If you have any question regarding our privacy policy, please contact us at info@amodisc.com."
/>
<div class="separator"></div>

<h4>©2021 by Amodisc Ltd </h4>
</div> 


    </>
  )




}
export default PrivacyPolicy

