import React from 'react'
import './TextSectionRight.css'

function TextSection(props) {
  return (
   <>
    
      <div className='tsr'>
      
      <div className="titleBox">
      <h1 style={{color:props.titleCol, textAlign:'center'}}>{props.title}</h1>
      <h2 style={{color:props.subtitleCol}}> {props.subtitle}</h2>
       
      </div>

      </div>
   </>
  )
}

export default TextSection