import React from 'react'
import '../../App.css'
import TopPic from '../TopPic'
import VidSection from '../VidSection'
import TextSection from '../TextSection'
import PureGrid from '../PureGrid'
import devices from '../../jsons/devices.json'
import vidList from '../../jsons/vidList.json'
import { Button } from '../Button'

import Footer from '../Footer'
import TextSectionRight from '../TextSectionRight'
import TextSectionLeft2pics from '../TextSectionLeft2pics'
import CookiesHeader from '../CookiesHeader'

export default function AmoSupport(){
    const vidsectionText = (
        <>
        <p>Custom cases are presented in beautiful animated 3D guides.</p>
        <p>Surgeons can benifit from continuous connectivity allowing the ultimate experience in support and education.</p>
        <p>Theatre practitioners have full 3D animations of instrument assembly at each step of the procedure.</p>
        <p>Industry reps have full control facilitating multiple cases simultaneously.</p>
        </>
    )
    const vidsectionText2 = (
        <>
        <p>Having a thorough understanding of individual's anatomy and surgical steps <i><strong>pre</strong></i>-operatively, is crucial in facilitating decision-making <i><strong>intra</strong></i>-operatively. </p>
        <br/>  <p>AMOsupport gives the surgeon detailed information before and during the operation.  </p>
        <br/>  <p>Complex cases are clarified.</p>
        <br/>  <p>Surgical flow is potentially improved.</p>
         </>
    )

    const spacialText = (
<>
<p>Full video connectivity for remote support.</p><br/>
<p>Full 3D animated procedural instructions for the suregeon.</p><br/>
<p>Full 3D animated instrument assembly instructions for the practitioner.</p><br/>
<p>Fully customisable workspace.</p><br/>
<p>An unobstructed surgical field that remains in full focus.</p> 
</>
    )
    
        return (
            <>
        <div id='amosupportTop'></div>
        <CookiesHeader />
       <TopPic 
       text='AMOsupport'  
       subtext ='Connected Spatial Computing in the O.R.' 
       subtext2='Full connectivity to anyone in the world.  All the information the surgeon and theatre practitioner needs, at their fingertips. Beautiful 3D representations of your patient and instruments.'  
       imgsrc="url(/images/amosupport-img.jpg)"blackover='true'
       />
       <VidSection 
    title='Ideal for challenging cases.' 
    subtitle={vidsectionText}
    source={vidList.amosupport}
       />
      <TextSectionRight
      title='A spatial interface designed around the surgical team' 
      subtitle=""  
      image="https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/SpatialComputingGlow.png"  
      explain={spacialText}
      />
 <TextSectionLeft2pics
      title='Rehearse and Execute' 
      subtitle=""  
      image="https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/videos/tib-preop.gif"  
      image2="https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/sanitised_Image-circle-glow.png"  

      explain={vidsectionText2}
      />

        <TextSection
       subtitle="If you want to learn about building beautiful remote intaroperative support for your implants and innovations, please enquire below..."
       />
       <Footer />
            </>
        )
}