import React from 'react'
import '../../App.css'

import Footer from '../Footer'
import TopPic from '../TopPic'
import Team from '../Team'
import RightVidContainer from '../RightVidContainer'
import VidSection from '../VidSection'
import TextSectionRight from '../TextSectionRight'
import TextSectionLeft from '../TextSectionLeft'
import GridRow from '../GridRow'
import Separator from '../Separator'
import leadershipTeam from '../../../src/jsons/leadershipTeam.json'
import advisorTeam from '../../../src/jsons/advisorTeam.json'
import whyObjects from '../../../src/jsons/whyObjects.json'
import devices from '../../../src/jsons/devices.json'
import PureGrid from '../PureGrid'
import vidList from '../../jsons/vidList.json'
import CookiesHeader from '../CookiesHeader'

function About() {
const vidsectionText =  (
  <>
  <p>Bring equality to global healthcare through education technology.</p>
  <p>We create digital content to deliver medical education where it is most needed. We give clinicians the power to train each other any time, anywhere, so they can be confident in their trainees' abilities to deliver safe patient care. Never train alone. Trainers confident in trainees.</p>
  <p>A world where all trainees and trainers feel supported.</p>
  </>
)


const textSectionRightTitle = (
  <> 

  <p>91% Surgeons feel underskilled at the end of their training programmes</p><br></br>
  <p>83% NGOs were grounded during the COVID-19 pandemic</p><br></br>
  <p>Nurses desperately need more resources to progress in their careers</p><br></br>
  <p>Companies need more effective ways of training surgeons on their innovations</p>
  </>

)

const needPlatform = (

  <>
  <h2>We made our platform modular.</h2>
  <br></br>
  <p>We needed to build a digital platform that permits remote training for all in all environments.  So we did! </p>
  <br></br>
  <p>Our platform has enterprise level security and is highly scalable to allow an infinite amount of interactive content. The content can be delivered on any device from smartphone to virtual to mixed reality, depending on what and where you need to deliver it.</p>
  <br></br>
  <p>Just contact us below to find out how we can build that content for your needs to hit your training goals.</p>
  </>

)

const modularPlatform = (

  <>
  <h2>We made our platform scalable.</h2>
  <br/>
  <p>We've created a platform that doctors and allied professionals can use to improve their skills, and we also teach them to make their own realistic simulators that are not expensive but mimic anatomical tissue well. This helps medical professionals become experts in their field, and it benefits countries by unlocking the potential of their populations to save lives, reduce costs, and enhance patient care globally. 
   </p>
   <br/>
   <p>Our innovative teaching methods, speed up the adoption of new medical tools. Medical professionals have greater access to comprehensive training providing success stories worldwide.</p>
  </>
)


  return (
    <>
    <div id='top'></div>
    <CookiesHeader />
   <TopPic 
   text='A platform that unifies medical training'  
   subtext ='Accessible healthcare training for all' 
   subtext2='Our Unified Training Platform supports Healthcare systems around the world through education.  We can help you create content for the right people in the right environment on the right devices.'  
   imgsrc="url(https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/amologo-space.jpg)"
  blackover='true'
   />
   <VidSection 
title='Amodisc is on a mission that started 1000s of years ago' 
subtitle={vidsectionText}
source={vidList.hippocretes}
   />
   <TextSectionRight 
   title="Global Healthcare Training is fragmented."
   subtitleCol='red'
   subtitle={textSectionRightTitle} 
   explain={needPlatform}
   />
     <PureGrid contents={devices}/>
   <TextSectionLeft 
   title='Enhancing surgical skills through digital training and affordable simulators.' 
   subtitle=""  
   image="https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/Ex-Fix-Tissue-App.jpeg"  
   explain={modularPlatform}
   />
   <Separator />
   <GridRow contents={whyObjects}/>
   <Team 
   title="Our Leadership Team" 
   text="We have strong expertise in clinical, academic, software and business development" 
   team={leadershipTeam} 
   titlecol = 'cyan'/>

   <div style={{backgroundColor:'white', height:1, width:'70%', display:'flex', margin:'auto'}}></div>

   <Team 
   title="Our Advisors" 
   text="Our panel of highly accomplished advisors" 
   team={advisorTeam} 
   titlecol = 'rgb(0,255,0)'/>


    <Footer />
    </>
  )
}

export default About