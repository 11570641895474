import React from 'react'
import './TwoColumns.css'

//!SECTIONimport icon from './svg/googleCard.svg'

function TwoColumns(props) {
   
    const column = props.column
// NB.  image icon svgs have to remain in this file !!! 'require' does not like to be passed around as a sring...

    const IMAGES = {
        'gc': require('./svg/googleCard.svg').default,
        'hl': require('./svg/hololens.svg').default,
        'pc': require('./svg/pc.svg').default,
        'ip': require('./svg/iphone.svg').default,
    }; 
    


    const getImage = (name) => {
        return(IMAGES[name])
    };

  //////////////////////////////////////////////////////////  
  return (
    <>
<div className="maincontainer">

{column.map((data) => {
    return (
    <><div className="column">
    <div className="column-title-box">
    <h2>{data.title}</h2>
    <div className='svg-box'>{data.icons.map((ic) => (
        <img src={getImage(ic)}  alt='case study' width='10%'/>
    ))}</div>
    
    

</div>
<div className="column-image">
    <img src={data.image} alt='case study' width='100%'/>
</div>
<div className="column-text">
    <p>{data.text}</p>
</div>
</div>
</>
)}
)}


{/* <div class="column">
{column2.map((data) => (
    <>
    <div class="column-title-box">
    <h2>{data.title}</h2>
    {data.icons}
</div>
<div class="column-image">
    <img src={data.image} alt='case study'/>
</div>
<div class="column-text">
    <p>{data.text}</p>
</div>
</>
))}

</div> */}

</div>
    </>
  )
}

export default TwoColumns