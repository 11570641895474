import React, {useRef, useEffect} from 'react'
import { Button } from './Button'
import '../App.css'
import './AmoSection.css'
import VideoShow from './VideoShow'
import { Link } from 'react-router-dom'

function AmoSection() {
const linkClick =  (
  <Link  to='/about'/>
)

  return (
    <div className='amo-container'>
<VideoShow 
source={`${process.env.PUBLIC_URL}https://advaitstestbucket.s3.eu-west-1.amazonaws.com/website-videos/video-2.mp4`}
/>
{/* <div className='innerD'
          dangerouslySetInnerHTML={{
            __html: `<video className="app__backgroundVideo" autoplay loop muted playsinline>
      <source src=${`${process.env.PUBLIC_URL}https://advaitstestbucket.s3.eu-west-1.amazonaws.com/website-videos/video-2.mp4`} type="video/mp4" />
      Your browser does not support video.
</video>`,
          }}
        
        ></div> */}

{/* <video src={`${process.env.PUBLIC_URL}https://advaitstestbucket.s3.eu-west-1.amazonaws.com/website-videos/video-2.mp4`} autoPlay loop muted playsinline /> */}
<p>Supporting education that impacts on patient lives</p>
<h1>Let's build Global Healthcare Education together</h1>
<div className='amo-btns-interimcontainer'>
<div className="amo-btns">
    <Button className='btns' buttonStyle='btn--outline' link='/about'>Learn more</Button>
</div>
</div>
    </div>
    
  )
}

export default AmoSection