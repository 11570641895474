import React from 'react'
import './GridRow.css'

function GridRow(props) {

const gridContents = props.contents

  return (
    <>
   <div className="gridRowContainer">
   {(gridContents).map(co => 
<div className="gridObject">
    <div className="objImgContainer">  
    
  
    <img src={`${co.image}`} height='100%' alt='support med education'/>
    <h2>{co.text}</h2>
    <h2>{co.imgText}</h2>
    </div>
       
</div>
   )}
   </div>
   {/* <div className="separator"></div> */}
   {/* <div className="separator"></div> */}
    </>
  )
}

export default GridRow