import React from 'react'
import './PureGrid.css'

function PureGrid(props) {

const gridContents = props.contents

  return (
    <>
    
   <div className="PGgridRowContainer">
   {(gridContents).map(co => 
<div className="PGgridObject">
    
    <div className="PGobjImgContainer"> 
    <img src={`${co.image}`} height='100%' alt='device'/>
    <h3>{co.imgText}</h3>
    </div>
       
</div>
   )}
   </div>
   {/* <div className="separator"></div>
   <div className="separator"></div> */}
  
    </>
  )
}

export default PureGrid