import React from 'react'
import CardItem from './CardItem'
import './Cards.css'

function Cards() {
  return (

    <div className='cards'id='solutions' > 
   <div className='sectionTop'></div>
<h1 style={{marginBottom: 16}}>A unified training platform for unique challenges</h1>
<p style={{textAlign:'center', margin:10, color:'black'}}>Each healthcare system has its own training needs. <br/> Our Unified Training Platform allows us to build affordable scalable content to suit any need.</p>
<h3>Here are some of our solutions</h3>
<div className="cards__container" >
    <div className="cards__wrapper" >
        <ul className="cards__items">
            <CardItem 
            src="/images/amotutor-img.jpg"
            text="Digitally connect 
            trainers & trainees
            "
            label="AmoTutor" 
            path="/amotutor/#amotutorTop"          
            />
            <CardItem 
            src="/images/amocourse-img.jpg"
            text="Expand medical skill-sets where most needed
            "
            label="AmoCourse" 
            path="/amocourse/#amocourseTop"          
            />
            <CardItem 
            src="/images/amosupport-img.jpg"
            text="Intraoperative support and training
            "
            label="AmoSupport" 
            path="/amosupport/#amosupportTop"          
            />
        </ul>
    </div>
</div>
    </div>
  
  )
}

export default Cards