import React from 'react'
import '../App.css' 
import './TextSectionRight.css'

function TextSectionLeft2pics(props) {
  return (
    <>
    <div className="separator"></div>
      <div className='tsr'>
      <div className="explainBox">
        <p>{[props.explain]}</p>
      </div>
      <div className="titleBox">
      <h1 style={{color:props.titleCol}}>{props.title}</h1>
      <h2 style={{color:props.subtitleCol}}> {props.subtitle}</h2>
      <div className='doubleImage' >
        <div className="imageBox" style={{marginLeft:10,marginRight:10}}>
        <img src={props.image} alt="" height="250em" /> 
    </div>
    <div className="imageBox" style={{marginRight:10,marginLeft:10}}>
        <img src={props.image2} alt="" height="250em" /> 
    </div>
    </div>
      </div>

      </div>
      
      </>
    )
  
}

export default TextSectionLeft2pics