
import React from 'react'
import { Link } from 'react-router-dom'
import './WhyUs.css'
import { Button } from './Button'
function WhyUs() {
  return (
    <div className='whyUs'>
        <div className="whyUs__container">
    <div className="whyUs__wrapper">
        <img src="../images/trainee.jpg" alt="" className="whyUs__picFrame" />
        <div className="whyUs__textcontiner">
        <h3>Why us</h3>
<h1>Build confidence in trainees</h1>
<br/>
<p>The key to training is building confidence in those we train.  Amodisc believes in connecting trainer and trainees together in fun and interactive ways.  From gaining trust, to empowering through leadership, we build the tools to assist both trainer and trainee in this journey.</p>
<br/>
<p>"When inspirational training co-exists with clinical excellence, the combination can be transformative"</p>
<div className="reference">Donald Campbell, JTO March 2024</div>
<div className="whyUs-btns">
    <Button className='btns' buttonStyle='btn--outline' >Learn more</Button>
</div>
</div>
</div>

</div>

    {/* <div className='trusted'>
        <h1>Trusted by the best</h1>
    <div className="logos">
        <div className="logo-holder">
            <img src="../images/NHS-logo.png" alt=""  height={100}/>
        </div>
        <div className="logo-holder">
            <img src="../images/Unitar-Logo.png" alt=""  height={100}/>
        </div>
        <div className="logo-holder">
            <img src="../images/operation-smile.jpg" alt=""  height={100}/>
        </div>
        <div className="logo-holder">
            <img src="../images/DBT_Red.webp" alt=""  height={100}/>
        </div>
    </div>
    </div> */}
    </div>
  )
}

export default WhyUs