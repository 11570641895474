import React from 'react'
import './JustPic.css'
function JustPic(props) {
  return (
    <>
    <div className='JP' style={{backgroundColor:props.background}}>
    <img src={props.source} alt="" height={props.height} width={props.width} />
    </div>
        </>
  )
}

export default JustPic