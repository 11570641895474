import './MultiDevices.css'

import React from 'react'
import VideoShow from './VideoShow'

function RightVidContainer(props) {
  return (
    <>
    <div className="textoverlay"><h1>{props.text}</h1></div>
{
props.rightSided ?

<div className="fullWrapper">


    <div className="imageCont">
   

       <img src={props.imgsrc} alt="" className="justImage" /> 
         
    </div>
    <div className="VidCont" style={{justifyContent:"center"}}>
      
      {/* <video src={props.vidsrc} alt="" className="justVid" autoPlay muted loop />  */}
      <VideoShow 
source={props.vidsrc}
/>
    </div>

</div>
:

<div className="fullWrapper">

<div className="VidCont" style={{justifyContent:"center"}}>

<VideoShow 
source={props.vidsrc}
/>
       {/* <video src={props.vidsrc} alt="" className="justVid" autoPlay muted loop />  */}
       
</div>
    <div className="imageCont">
      
       <img src={props.imgsrc} alt="" className="justImage" /> 
      
    </div>
    
  
</div>
}
    </>
  )
}

export default RightVidContainer