import React from 'react'
import '../../App.css'

import TextSectionLeft from '../TextSectionLeft'
import Footer from '../Footer'
import TopPic from '../TopPic'
import Separator from '../Separator'
import PureGrid from '../PureGrid'
import JustPic from '../JustPic'
import VidSection from '../VidSection'
import VidSectionLeft from '../VidSectionLeft'
import TwoColumns from '../TwoColumns'
import cs from '../../jsons/caseStudies.json'
import vidList from '../../jsons/vidList.json'
import CookiesHeader from '../CookiesHeader'

function CaseStudies() {
const vidsectionText =  (
  <>
  <p style={{color:'white'}}>Imagine industry reps simultaneously conducting multiple accounts.</p>
  <p style={{color:'white'}}>With Amosupport, the rep can guide the surgeon AND the theatre practitioner through complex operations while having full control.  When required the rep can bring other key parties into the operating room thus providing the surgeon with all the information they need during the case. </p>
  <p style={{color:'white'}}>The surgeon can use the same technology to teach others remotely. They can arrange all the information in space while keeping focus on their patient.</p>
  <p style={{color:'white'}}>Amosupport provides a truly engaging experience where the whole team feels supported and industry can promote their innovations while increasing their reach.</p>
  <p style={{color:'white'}}>True spatial computing for the OR</p>
  </>
)

const amoCourseText = (
  <>
  <p style={{color:'white'}}>Support your healthcare training ecosystems with Amocourse</p>
  <p style={{color:'white'}}>Amocourse is a fully customised course delivered on the Amodisc Platform&#169;  that is tailored towards your teaching needs.</p>
  <p style={{color:'white'}}>Remote teaching is more effective with the ability to provide comprehensive content across a multitude of devices to multiple countries.</p>
  <p style={{color:'white'}}>Amocourse is ideal for NGOs in LMICs as well as high income countries wishing to train a workforce of medics and allied professionals.</p>
  <p style={{color:'white'}}>Save money, save the planet and train a greater number of clinicians globally. </p>
  </>
)


const amoTutorText = (
  <>
  <p style={{color:'white'}}>A customisable app that connects trainers and trainees over procedures</p>
  <p style={{color:'white'}}>The trainer customises the procedure simulators how they like to perform it. The trainee can select which trainer's simulators they want to rehearse.</p>
  <p style={{color:'white'}}>Both can communicate at each step of the way and the trainee gains a more comprehensive understanding of the procedure.</p>
  <p style={{color:'white'}}>Trainers are given the opportunity to create.  Trainees understand more and Training institutions are confident that training is being delivered.</p>
  <p style={{color:'white'}}> A world where every trainee and trainer is supported.  </p>
  </>
)

  return (
   <> 
   <div id='top'></div>  
   <CookiesHeader />
<JustPic 
source= "https://amo-general.s3.eu-west-1.amazonaws.com/website-assets/images/flyingAppsWB.jpg"
width='60%'
background='white'

/>

<VidSection 
background='black'
titlecolour='#0033CC'
textcolour='white'
title='Amosupport' 
subtitle={vidsectionText}
source={vidList.amosupport} 
/>
<VidSectionLeft
background='black'
titlecolour='#AB00EA'
textcolour='white'
title='Amocourse' 
subtitle={amoCourseText}
source={vidList.amocourse}  
/>

<VidSection 
background='black'
titlecolour='#009103'
textcolour='white'
title='Amotutor' 
subtitle={amoTutorText}
source={vidList.amotutor} 
/>
<TwoColumns 
column={cs}
/>
<Footer />
</>

  )
}

export default CaseStudies