import React from 'react'
import'./MultiDevices.css'
import RightVidContainer from './RightVidContainer'

function MultiDevices() {
  return (
    <div className='MultiDevices'>
           <h3>No matter...</h3>
        <div className="md-container">
          <RightVidContainer 
          rightSided={true}
          imgsrc='/images/smartPhone.png'
          vidsrc={`${process.env.PUBLIC_URL}https://advaitstestbucket.s3.eu-west-1.amazonaws.com/website-videos/b-amotutorShort-light.mp4`}
          text='what ever'
          />

<RightVidContainer 
          rightSided={false}
          imgsrc='/images/googlecard.png'
          vidsrc={`${process.env.PUBLIC_URL}https://advaitstestbucket.s3.eu-west-1.amazonaws.com/website-videos/b-BOFASShort.mp4`}
          text='whom ever'
          />

<RightVidContainer 
          rightSided={true}
          imgsrc=    "/images/metaquest.png"
          vidsrc={`${process.env.PUBLIC_URL}https://advaitstestbucket.s3.eu-west-1.amazonaws.com/website-videos/b-amovisio.mp4`}
          text='how ever'
          />

<RightVidContainer 
          rightSided={false}
          imgsrc=    "/images/hololens2.png"
          vidsrc={`${process.env.PUBLIC_URL}https://advaitstestbucket.s3.eu-west-1.amazonaws.com/website-videos/b-hololensOpBAckGround.mp4`}
          text='where ever'
          />
          <h3>...you teach, </h3>
        </div>
<div className="multidev-ender">
        
        <h2>we build it !</h2>
<div className="simpic-container">
 
<img src="/images/cleft.jpg" alt="" className="simPic" />
</div>
<div className="lastcomment">

    <h4>(We even show you how to build your own low cost simulators)</h4>
</div>
   </div>     
   </div>
  )
}

export default MultiDevices