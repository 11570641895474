import React from 'react'
import '../App.css' 
import './TextSectionRight.css'

function TextSectionLeft(props) {
  return (
    <>
    <div className="separator"></div>
      <div className='tsr'>
      <div className="explainBox">
        <p>{[props.explain]}</p>
      </div>
      <div className="titleBox">
      <h1 style={{color:props.titleCol}}>{props.title}</h1>
      <h2 style={{color:props.subtitleCol}}> {props.subtitle}</h2>
        <div className="imageBox">
        <img src={props.image} alt="" width="50%" /> 
    </div>
      </div>

      </div>
      
      </>
    )
  
}

export default TextSectionLeft