import React ,{useState} from 'react'
import './Team.css'
import '../App.css' 



function Team(props) {
  
  const [mouseOver, setMouseOver] = useState(false);
     
const team = props.team
const mouseHit = (e,textin) => { 
  setMouseOver(!mouseOver)
  
  const newDivId = e.target.id+'dix'

  let text = e.target.id
  let stringLength = text.length-3
  let endString = text.substr(stringLength,3)
  console.log('end string is ...',endString)

  if(text && endString !=='dix') {
 
  const foo = document.getElementById(newDivId)
  
  !mouseOver ? foo.innerHTML=textin : foo.innerHTML=''
  !mouseOver ? foo.style.backgroundColor = 'white' : foo.style.backgroundColor = 'black'

}

}

////

////

  return (
    <div className='team'>
        <div className='textsec'>
            <h1 style={{color:`${props.titlecol}`}}>{props.title}</h1>
            <h2>{props.text}</h2>
        </div>
        <div className="imgs-holder" >
        
        {(team).map(co => { 
          return(
       <div div className='img-hold' 
       onMouseDownCapture={(p) => mouseHit(p,co.text)}
       // : <div id={co.name} style={{marginBottom:20, textAlign:'justify'}}>{co.text}</div>}
       >
           <img src={`${co.image}`} width = '100%' id={co.name}/> 
          <div className='name' style={{color:`${props.titlecol}`}}>{co.name}</div>
          <div className='jobTitle'>{co.jobTitle}</div>
          <div id={co.name+'dix'}  className='teamMemberInfo'></div>
          </div>
        )
        }) }
        </div>
        </div>
  )
}

export default Team