import React from 'react'
import '../../App.css'
import AmoSection from '../AmoSection'
import Cards from '../Cards'
import Footer from '../Footer'
import WhyUs from '../WhyUs'
import CaseStudy from '../CaseStudy'
import Trusted from '../Trusted'
import WhoWeHelp from '../WhoWeHelp'
import MultiDevices from '../MultiDevices'
import GridRow from '../GridRow'

import whyObjects from '../../../src/jsons/whyObjects.json'
import Separator from '../Separator'
import CookiesHeader from '../CookiesHeader'



function Home () {


    return (
        <>
        <div id='top'></div> 
       
<CookiesHeader />

        <AmoSection />
       
        <Separator />
        <Separator /> 
        <GridRow contents={whyObjects}/>  
        <Cards  />   
        <MultiDevices />
        <WhyUs />
       
        <WhoWeHelp />
        <CaseStudy />
      
       
        <Trusted />
        <Footer />
        </>
        
    )
}

export default Home